import React from "react";
import { useGetFormContext } from "../../Providers/FormProvider";
import { Button } from "../Button/Button";
import ButtonTypes from "../Button/ButtonTypes";
import "./FormComponent.scss";

export type FormProps = {
    label?: string;
    extraClass?: string;
    validationField?: string;
    style?:React.CSSProperties;
}

interface FormComponentProps extends FormProps {
    children: React.ReactNode;
    addNewFunction?: () => void;
}


export default function FormComponent(props:FormComponentProps) {
    const { validation } = useGetFormContext();
    const extraClass = props.extraClass ? props.extraClass : "";
    const validationArray: any[] = [];

    if (validation && props.validationField) {
        let validationForField = validation[props.validationField]
        if (validationForField) {
            validationForField.forEach(item => {
                validationArray.push(item.message);
            });
        }
    }

    const label = (
        props.label
            ?
            <div className="form_input_label text_white">
                {props.label}
            </div>
            :
            null
    );

    return (
        <div className={"form_input_container " + extraClass} style={props.style}>
            {label}
            <div>
                <div className="form_validation_and_add_container">
                    <div className="validation">{validationArray}</div>
                    <div className="form_add_new">
                        {props.addNewFunction?
                            <Button
                                label="Add New"
                                type={ButtonTypes.add}

                                clickEvent={props.addNewFunction}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    );
}