import React, { forwardRef } from "react";
import FormComponent, { FormProps } from "./FormComponent";

export type MainInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & FormProps

export const FormInput = forwardRef((props: MainInputProps, ref:React.ForwardedRef<HTMLInputElement | null>) => {
    
    const {extraClass, label, validationField, ...inputProps} = props;

    return (
        <FormComponent
            extraClass={extraClass}
            label={label}
            validationField={validationField}
        >
            <input
                {...inputProps}
                className="form_container form_input text_white"
                ref={ref}
            >
            </input>
        </FormComponent>
    );
});
